export { COUNTRIES, COUNTRY_NAMES, COUNTRY_VALUES } from "./country";
export { LOCALES, LOCALE_VALUES, LOCALE_NAMES } from "./locale";

export const ROLE = Object.freeze({
  OWNER: "owner",
  EDITOR: "editor",
  VIEWER: "viewer",
});

// Extract the keys from the object
export type Role = (typeof ROLE)[keyof typeof ROLE];

// !IMPORTANT: The order of this array is used to determine the role hierarchy
export const ROLE_VALUES = [ROLE.OWNER, ROLE.EDITOR, ROLE.VIEWER] as const;

export const ROLE_NAMES = Object.freeze({
  [ROLE.OWNER]: "Owner",
  [ROLE.EDITOR]: "Editor",
  [ROLE.VIEWER]: "Viewer",
  admin: "Admin",
});

export const TOKEN_TYPE = Object.freeze({
  PUBLISH: "publish",
  SUBSCRIBE: "subscribe",
  VERIFICATION: "verification",
  CONNECTION_STATE: "connection_state",
  CONNECTION_TOKEN_STATE: "connection_token_state",
  CONNECTION_CUSTOM_STATE: "connection_custom_state",
  SESSION: "session",
  INVITE: "invite",
  TRIGGER: "trigger",
});

export const CURRENCY = {
  USD: "usd",
  EUR: "eur",
  DKK: "dkk",
} as const;

export const CURRENCY_VALUES = [
  CURRENCY.USD,
  CURRENCY.EUR,
  CURRENCY.DKK,
] as const;

export const CURRENCY_NAMES = {
  [CURRENCY.DKK]: "Danish Kroner",
  [CURRENCY.EUR]: "Euro",
  [CURRENCY.USD]: "US Dollars",
} as const;

export const INTERVAL = Object.freeze({
  MONTH: "month",
  YEAR: "year",
});

export const INTERVAL_VALUES = [INTERVAL.MONTH, INTERVAL.YEAR] as const;

export const NOTIFICATION_SUBSCRIPTION_TOKEN_KEY =
  "notificationSubscriptionToken";

export const PUBLIC_IMAGES_PATHS = Object.freeze({
  APP_LOGO: "app_logo/",
  PROFILE: "profile/",
  CONNECTION_GROUP_LOGO: "connection_group/",
  COMPANY_LOGO: "company_logo/",
});

export const AUTHENTICATION_TYPES = Object.freeze({
  OAUTH2: "oauth2",
  CREDENTIALS: "credentials",
  CUSTOM: "custom",
});

export const AUTHENTICATION_TYPE_VALUES = [
  AUTHENTICATION_TYPES.OAUTH2,
  AUTHENTICATION_TYPES.CREDENTIALS,
  AUTHENTICATION_TYPES.CUSTOM,
] as const;

export const AUTHENTICATION_TYPE_NAMES = Object.freeze({
  [AUTHENTICATION_TYPES.OAUTH2]: "OAuth2",
  [AUTHENTICATION_TYPES.CREDENTIALS]: "Credentials",
  [AUTHENTICATION_TYPES.CUSTOM]: "Custom",
});

export const PAYMENT_STATUS = Object.freeze({
  COMPLETED: "completed",
  PENDING: "pending",
  FAILED: "failed",
});

export const PAYMENT_STATUS_VALUES = [
  PAYMENT_STATUS.COMPLETED,
  PAYMENT_STATUS.PENDING,
  PAYMENT_STATUS.FAILED,
] as const;

export const SUBSCRIPTION_STATUS = Object.freeze({
  ACTIVE: "active",
  CANCELED: "canceled",
  INACTIVE: "inactive",
  PENDING: "pending",
  EXPIRED: "expired",
});

export const SUBSCRIPTION_STATUS_VALUES = [
  SUBSCRIPTION_STATUS.ACTIVE,
  SUBSCRIPTION_STATUS.CANCELED,
  SUBSCRIPTION_STATUS.INACTIVE,
  SUBSCRIPTION_STATUS.PENDING,
  SUBSCRIPTION_STATUS.EXPIRED,
] as const;

export const STANDARD_SUBSCRIPTION_PLAN = Object.freeze({
  STARTER: "starter",
  BUSINESS: "business",
  BUSINESS_PREMIUM: "business_premium",
});

export const STANDARD_SUBSCRIPTION_PLAN_VALUES = [
  STANDARD_SUBSCRIPTION_PLAN.STARTER,
  STANDARD_SUBSCRIPTION_PLAN.BUSINESS,
  STANDARD_SUBSCRIPTION_PLAN.BUSINESS_PREMIUM,
] as const;

export const SUBSCRIPTION_TYPE = Object.freeze({
  STANDARD: "standard",
  CUSTOM: "custom",
});

export const SUBSCRIPTION_TYPE_VALUES = [
  SUBSCRIPTION_TYPE.STANDARD,
  SUBSCRIPTION_TYPE.CUSTOM,
] as const;

export const PAYMENT_PLATFORM = Object.freeze({
  STRIPE: "stripe",
  ECONOMIC: "economic",
});

export const PAYMENT_PLATFORM_VALUES = [
  PAYMENT_PLATFORM.STRIPE,
  PAYMENT_PLATFORM.ECONOMIC,
] as const;

export const PLAN_PRODUCT = Object.freeze({
  LIGHTHOUSE: "lighthouse",
});

export const PLAN_PRODUCT_VALUES = [PLAN_PRODUCT.LIGHTHOUSE] as const;

export const WORKFLOW_RUN_STATUS = Object.freeze({
  PENDING: "pending",
  RUNNING: "running",
  FINISHED: "finished",
  FAILED: "failed",
  SKIPPED: "skipped",
});

export const WORKFLOW_RUN_STATUS_VALUES = [
  WORKFLOW_RUN_STATUS.PENDING,
  WORKFLOW_RUN_STATUS.RUNNING,
  WORKFLOW_RUN_STATUS.FINISHED,
  WORKFLOW_RUN_STATUS.FAILED,
  WORKFLOW_RUN_STATUS.SKIPPED,
] as const;

export const WORKFLOW_RUN_STATUS_NAMES = {
  [WORKFLOW_RUN_STATUS.PENDING]: "Pending",
  [WORKFLOW_RUN_STATUS.RUNNING]: "Running",
  [WORKFLOW_RUN_STATUS.FINISHED]: "Finished",
  [WORKFLOW_RUN_STATUS.FAILED]: "Failed",
  [WORKFLOW_RUN_STATUS.SKIPPED]: "Skipped",
} as const;

export const WORKFLOW_RUN_EVENT_TYPE = Object.freeze({
  API_GATEWAY_PROXY_EVENT_V2: "api_gateway_proxy_event_v2",
  OTHER: "other",
});

export const WORKFLOW_RUN_EVENT_TYPE_VALUES = [
  WORKFLOW_RUN_EVENT_TYPE.API_GATEWAY_PROXY_EVENT_V2,
  WORKFLOW_RUN_EVENT_TYPE.OTHER,
] as const;

export const EMAIL_SENDER_TYPES = Object.freeze({
  INVITE_USER: "invite_user",
  ACCOUNT_VERIFICATION: "account_verification",
  FORGOT_PASSWORD: "forgot_password",
  ACCOUNT_TAKEOVER_NOTIFICATION: "account_takeover_notification",
  ADMIN_NEW_USER: "admin_new_user",
  NEW_USER: "new_user",
  FAILED_WORKFLOW_RUN: "failed_workflow_run",
  SEND_INVOICE: "send_invoice",
  SEND_PAYMENT_REMINDER: "send_payment_reminder",
  SEND_EXHAUST_PLAN_REMINDER: "send_exhaust_plan_reminder",
});

export const EMAIL_SENDER_TYPE_VALUES = [
  EMAIL_SENDER_TYPES.INVITE_USER,
  EMAIL_SENDER_TYPES.ACCOUNT_VERIFICATION,
  EMAIL_SENDER_TYPES.FORGOT_PASSWORD,
  EMAIL_SENDER_TYPES.ACCOUNT_TAKEOVER_NOTIFICATION,
  EMAIL_SENDER_TYPES.ADMIN_NEW_USER,
  EMAIL_SENDER_TYPES.NEW_USER,
  EMAIL_SENDER_TYPES.FAILED_WORKFLOW_RUN,
  EMAIL_SENDER_TYPES.SEND_INVOICE,
  EMAIL_SENDER_TYPES.SEND_PAYMENT_REMINDER,
  EMAIL_SENDER_TYPES.SEND_EXHAUST_PLAN_REMINDER,
] as const;

export const expirationOptions = Object.freeze([
  "7 Days",
  "30 Days",
  "60 Days",
  "90 Days",
  "Never Expire",
]);

export const PROSPECT_STATUS = Object.freeze({
  CREATED: "created",
  MEETING_BOOKED: "meeting_booked",
  MEETING_COMPLETED: "meeting_completed",
  IMPLEMENTATION: "implementation",
  COMPLETED: "completed",
});

export const PROSPECT_STATUS_VALUES = [
  PROSPECT_STATUS.CREATED,
  PROSPECT_STATUS.MEETING_BOOKED,
  PROSPECT_STATUS.MEETING_COMPLETED,
  PROSPECT_STATUS.IMPLEMENTATION,
  PROSPECT_STATUS.COMPLETED,
] as const;

export const PROSPECT_STATUS_NAMES = Object.freeze({
  [PROSPECT_STATUS.CREATED]: "Created",
  [PROSPECT_STATUS.MEETING_BOOKED]: "Meeting Booked",
  [PROSPECT_STATUS.MEETING_COMPLETED]: "Meeting Completed",
  [PROSPECT_STATUS.IMPLEMENTATION]: "Implementation",
  [PROSPECT_STATUS.COMPLETED]: "Completed",
});

export const USER_NOTIFIER_TYPES = {
  FAILED_WORKFLOW_RUN: "failed_workflow_run",
} as const;

export const USER_NOTIFIER_TYPE_VALUES = [
  USER_NOTIFIER_TYPES.FAILED_WORKFLOW_RUN,
] as const;

export const HTTP_METHODS = [
  "ANY",
  "GET",
  "POST",
  "PUT",
  "PATCH",
  "DELETE",
] as const;

export const TRIGGER_TARGET_TYPES = ["http", "lambda"] as const;

export const TRIGGER_TYPES = ["schedule", "webhook"] as const;

export const INTERNAL_NOTIFIER_TYPES = {
  FAILED_WORKFLOW_RUN: "failed_workflow_run",
  NEW_USER: "new_user",
  NEW_COMPANY: "new_company",
  NEW_PROSPECT: "new_prospect",
} as const;

export const INTERNAL_NOTIFIER_TYPE_VALUES = [
  INTERNAL_NOTIFIER_TYPES.FAILED_WORKFLOW_RUN,
  INTERNAL_NOTIFIER_TYPES.NEW_USER,
  INTERNAL_NOTIFIER_TYPES.NEW_COMPANY,
  INTERNAL_NOTIFIER_TYPES.NEW_PROSPECT,
] as const;

export const PAYMENT_METHOD_TYPES = {
  CARD: "card",
} as const;

export const PAYMENT_METHOD_TYPE_VALUES = [PAYMENT_METHOD_TYPES.CARD] as const;

export const PLAN_PRICE_MODES = {
  FLAT: "flat",
  TIERED_GRADUATED: "tiered_graduated",
} as const;

export const PLAN_PRICE_MODE_VALUES = [
  PLAN_PRICE_MODES.FLAT,
  PLAN_PRICE_MODES.TIERED_GRADUATED,
] as const;

export const WORKFLOW_MODES = {
  LIVE: "live",
  DEV: "dev",
} as const;

export const WORKFLOW_MODE_VALUES = [
  WORKFLOW_MODES.LIVE,
  WORKFLOW_MODES.DEV,
] as const;

export const WORKFLOW_MODE_NAMES = {
  [WORKFLOW_MODES.LIVE]: "Live",
  [WORKFLOW_MODES.DEV]: "Dev",
} as const;

export const API_KEY_TYPES = {
  SERVICE: "service",
  PERSONAL: "personal",
  ADMIN: "admin",
} as const;

export const API_KEY_TYPE_VALUES = [
  API_KEY_TYPES.SERVICE,
  API_KEY_TYPES.PERSONAL,
  API_KEY_TYPES.ADMIN,
] as const;

export const API_KEY_TYPES_SHORT_CODES = {
  [API_KEY_TYPES.SERVICE]: "sk",
  [API_KEY_TYPES.PERSONAL]: "pk",
  [API_KEY_TYPES.ADMIN]: "ak",
} as const;

export const ECONOMIC_USAGE_PRODUCT = "extra-usage";

export const NET_8_PAYMENT_TERMS_NUMBER = 1;

export const PAYMENT_TERMS_NUMBER_FOR_IMMEDIATE = 3;

export const PRODUCT = {
  LIGHTHOUSE: "lighthouse",
} as const;

export const PRODUCT_VALUES = [PRODUCT.LIGHTHOUSE] as const;

export const PRODUCT_NAMES = {
  [PRODUCT.LIGHTHOUSE]: "Lighthouse",
} as const;

// TODO: Move this to database
export const PLANS: Array<{
  slug: string;
  name: string;
  interval: string;
  idealFor: string;
  prices: Array<{
    monthly: number;
    currency: "dkk" | "eur" | "usd";
    amount: number;
  }>;
  items: Array<{
    name: string;
    summary?: string;
    currency?: "dkk" | "eur" | "usd";
  }>;
  limits: {
    numberOfApps: number;
  };
}> = [
  {
    slug: "starter-monthly",
    name: "Starter",
    interval: "month",
    idealFor: "Ideal for Small teams",
    prices: [
      {
        monthly: 19900,
        currency: "dkk",
        amount: 19900,
      },
      {
        monthly: 2900,
        currency: "usd",
        amount: 2900,
      },
      {
        monthly: 2700,
        currency: "eur",
        amount: 2700,
      },
    ],
    limits: {
      numberOfApps: 2,
    },
    items: [
      {
        name: "2 Apps",
      },
      {
        name: "250 Transactions",
        summary: "For example, 250 invoices",
      },
      {
        name: "Replay failed workflows",
      },
      {
        name: "30 days historical data",
      },
      {
        name: "Detailed log history",
      },
      {
        name: "5 min Synchronization",
      },
      {
        name: "Support via email",
      },
      {
        name: "Chat support via Lighthouse.",
      },
      {
        name: "DKK 1 per extra transaction",
        currency: "dkk",
      },
      {
        name: "$ 0.14 per extra transaction",
        currency: "usd",
      },
      {
        name: "€ 0.14 per extra transaction",
        currency: "eur",
      },
      {
        name: "Import of previous orders can be purchased",
      },
    ],
  },
  {
    slug: "starter-yearly",
    name: "Starter",
    interval: "year",
    idealFor: "Ideal for Small teams",
    prices: [
      {
        monthly: 16900,
        currency: "dkk",
        amount: 202800,
      },
      {
        monthly: 2500,
        currency: "usd",
        amount: 30000,
      },
      {
        monthly: 2300,
        currency: "eur",
        amount: 27600,
      },
    ],
    limits: {
      numberOfApps: 2,
    },
    items: [
      {
        name: "2 Apps",
      },
      {
        name: "250 Transactions",
        summary: "For example, 250 invoices",
      },
      {
        name: "Replay failed workflows",
      },
      {
        name: "30 days historical data",
      },
      {
        name: "Detailed log history",
      },
      {
        name: "5 min Synchronization",
      },
      {
        name: "Support via email",
      },
      {
        name: "Chat support via Lighthouse.",
      },
      {
        name: "DKK 1 per extra transaction",
        currency: "dkk",
      },
      {
        name: "$ 0.14 per extra transaction",
        currency: "usd",
      },
      {
        name: "€ 0.14 per extra transaction",
        currency: "eur",
      },
      {
        name: "Import of previous orders can be purchased",
      },
    ],
  },
  {
    slug: "business-monthly",
    name: "Business",
    interval: "month",
    idealFor: "Ideal for Startups",
    prices: [
      {
        monthly: 29900,
        currency: "dkk",
        amount: 29900,
      },
      {
        monthly: 4400,
        currency: "usd",
        amount: 4400,
      },
      {
        monthly: 4000,
        currency: "eur",
        amount: 4000,
      },
    ],
    limits: {
      numberOfApps: 2,
    },
    items: [
      {
        name: "3 Apps",
      },
      {
        name: "500 Transactions",
        summary: "For example, 500 invoices",
      },
      {
        name: "Replay failed workflows",
      },
      {
        name: "180 days historical data",
      },
      {
        name: "Detailed log history",
      },
      {
        name: "1 min Synchronization",
      },
      {
        name: "Support via email",
      },
      {
        name: "Chat support via Lighthouse.",
      },
      {
        name: "DKK 1 per extra transaction",
        currency: "dkk",
      },
      {
        name: "$ 0.14 per extra transaction",
        currency: "usd",
      },
      {
        name: "€ 0.14 per extra transaction",
        currency: "eur",
      },
      {
        name: "Import of previous orders can be purchased",
      },
    ],
  },
  {
    slug: "business-yearly",
    name: "Business",
    interval: "year",
    idealFor: "Ideal for Startups",
    prices: [
      {
        monthly: 24900,
        currency: "dkk",
        amount: 298800,
      },
      {
        monthly: 3600,
        currency: "usd",
        amount: 561600,
      },
      {
        monthly: 3400,
        currency: "eur",
        amount: 40800,
      },
    ],
    limits: {
      numberOfApps: 3,
    },
    items: [
      {
        name: "3 Apps",
      },
      {
        name: "500 Transactions",
        summary: "For example, 500 invoices",
      },
      {
        name: "Replay failed workflows",
      },
      {
        name: "180 days historical data",
      },
      {
        name: "Detailed log history",
      },
      {
        name: "1 min Synchronization",
      },
      {
        name: "Support via email",
      },
      {
        name: "Chat support via Lighthouse.",
      },
      {
        name: "DKK 1 per extra transaction",
        currency: "dkk",
      },
      {
        name: "$ 0.14 per extra transaction",
        currency: "usd",
      },
      {
        name: "€ 0.14 per extra transaction",
        currency: "eur",
      },
      {
        name: "Import of previous orders can be purchased",
      },
    ],
  },
  {
    slug: "business-premium-monthly",
    name: "Business Premium",
    interval: "month",
    idealFor: "Ideal for Growing Team",
    prices: [
      {
        monthly: 47900,
        currency: "dkk",
        amount: 47900,
      },
      {
        monthly: 7000,
        currency: "usd",
        amount: 7000,
      },
      {
        monthly: 6500,
        currency: "eur",
        amount: 6500,
      },
    ],
    limits: {
      numberOfApps: 4,
    },
    items: [
      {
        name: "4 Apps",
      },
      {
        name: "1000 Transactions",
        summary: "For example, 1000 invoices",
      },
      {
        name: "Replay failed workflows",
      },
      {
        name: "1 year historical data",
      },
      {
        name: "Detailed log history",
      },
      {
        name: "1 min Synchronization",
      },
      {
        name: "Support via email",
      },
      {
        name: "Chat support via Lighthouse.",
      },
      {
        name: "DKK 1 per extra transaction",
        currency: "dkk",
      },
      {
        name: "$ 0.14 per extra transaction",
        currency: "usd",
      },
      {
        name: "€ 0.14 per extra transaction",
        currency: "eur",
      },
      {
        name: "Import of previous orders can be purchased",
      },
    ],
  },
  {
    slug: "business-premium-yearly",
    name: "Business Premium",
    interval: "year",
    idealFor: "Ideal for Growing Team",
    prices: [
      {
        monthly: 39900,
        currency: "dkk",
        amount: 478800,
      },
      {
        monthly: 5800,
        currency: "usd",
        amount: 69600,
      },
      {
        monthly: 5400,
        currency: "eur",
        amount: 64800,
      },
    ],
    limits: {
      numberOfApps: 4,
    },
    items: [
      {
        name: "4 Apps",
      },
      {
        name: "1000 Transactions",
        summary: "For example, 1000 invoices",
      },
      {
        name: "Replay failed workflows",
      },
      {
        name: "1 year historical data",
      },
      {
        name: "Detailed log history",
      },
      {
        name: "1 min Synchronization",
      },
      {
        name: "Support via email",
      },
      {
        name: "Chat support via Lighthouse.",
      },
      {
        name: "DKK 1 per extra transaction",
        currency: "dkk",
      },
      {
        name: "$ 0.14 per extra transaction",
        currency: "usd",
      },
      {
        name: "€ 0.14 per extra transaction",
        currency: "eur",
      },
      {
        name: "Import of previous orders can be purchased",
      },
    ],
  },
] as const;
