export const LOCALES = {
  en_AI: "en-AI",
  en_AG: "en-AG",
  en_AU: "en-AU",
  en_BS: "en-BS",
  en_BB: "en-BB",
  en_BZ: "en-BZ",
  en_BM: "en-BM",
  en_BW: "en-BW",
  en_IO: "en-IO",
  en_VG: "en-VG",
  en_CM: "en-CM",
  en_CA: "en-CA",
  en_KY: "en-KY",
  en_CK: "en-CK",
  en_DK: "en-DK",
  en_DM: "en-DM",
  en_SZ: "en-SZ",
  en_FK: "en-FK",
  en_FJ: "en-FJ",
  en_GM: "en-GM",
  en_GH: "en-GH",
  en_GI: "en-GI",
  en_GD: "en-GD",
  en_GU: "en-GU",
  en_GY: "en-GY",
  en_IN: "en-IN",
  en_IE: "en-IE",
  en_JM: "en-JM",
  en_KE: "en-KE",
  en_KI: "en-KI",
  en_LS: "en-LS",
  en_LR: "en-LR",
  en_MW: "en-MW",
  en_MY: "en-MY",
  en_MT: "en-MT",
  en_MH: "en-MH",
  en_MU: "en-MU",
  en_MS: "en-MS",
  en_NA: "en-NA",
  en_NR: "en-NR",
  en_NZ: "en-NZ",
  en_NG: "en-NG",
  en_NU: "en-NU",
  en_NF: "en-NF",
  en_MP: "en-MP",
  en_PK: "en-PK",
  en_PW: "en-PW",
  en_PG: "en-PG",
  en_PH: "en-PH",
  en_PN: "en-PN",
  en_PR: "en-PR",
  en_RW: "en-RW",
  en_KN: "en-KN",
  en_LC: "en-LC",
  en_VC: "en-VC",
  en_WS: "en-WS",
  en_SC: "en-SC",
  en_SL: "en-SL",
  en_SG: "en-SG",
  en_SB: "en-SB",
  en_ZA: "en-ZA",
  en_GS: "en-GS",
  en_SS: "en-SS",
  en_LK: "en-LK",
  en_SR: "en-SR",
  en_TZ: "en-TZ",
  en_TO: "en-TO",
  en_TT: "en-TT",
  en_TC: "en-TC",
  en_TV: "en-TV",
  en_UG: "en-UG",
  en_GB: "en-GB",
  en_US: "en-US",
  en_UM: "en-UM",
  en_VI: "en-VI",
  en_WF: "en-WF",
  en_EH: "en-EH",
  en_YE: "en-YE",
  en_ZM: "en-ZM",
  en_ZW: "en-ZW",
  en_BH: "en-BH",
  en_BD: "en-BD",
  en_BT: "en-BT",
  en_BN: "en-BN",
  en_CY: "en-CY",
  en_ER: "en-ER",
  en_IR: "en-IR",
  en_IQ: "en-IQ",
  en_JO: "en-JO",
  en_KW: "en-KW",
  en_LB: "en-LB",
  en_LY: "en-LY",
  en_MV: "en-MV",
  en_MM: "en-MM",
  en_NP: "en-NP",
  en_OM: "en-OM",
  en_QA: "en-QA",
  en_SO: "en-SO",
  en_SD: "en-SD",
  en_SY: "en-SY",
  en_TL: "en-TL",
  en_AE: "en-AE",
} as const;

export const LOCALE_VALUES = [
  LOCALES.en_AI,
  LOCALES.en_AG,
  LOCALES.en_AU,
  LOCALES.en_BS,
  LOCALES.en_BB,
  LOCALES.en_BZ,
  LOCALES.en_BM,
  LOCALES.en_BW,
  LOCALES.en_IO,
  LOCALES.en_VG,
  LOCALES.en_CM,
  LOCALES.en_CA,
  LOCALES.en_KY,
  LOCALES.en_CK,
  LOCALES.en_DK,
  LOCALES.en_DM,
  LOCALES.en_SZ,
  LOCALES.en_FK,
  LOCALES.en_FJ,
  LOCALES.en_GM,
  LOCALES.en_GH,
  LOCALES.en_GI,
  LOCALES.en_GD,
  LOCALES.en_GU,
  LOCALES.en_GY,
  LOCALES.en_IN,
  LOCALES.en_IE,
  LOCALES.en_JM,
  LOCALES.en_KE,
  LOCALES.en_KI,
  LOCALES.en_LS,
  LOCALES.en_LR,
  LOCALES.en_MW,
  LOCALES.en_MY,
  LOCALES.en_MT,
  LOCALES.en_MH,
  LOCALES.en_MU,
  LOCALES.en_MS,
  LOCALES.en_NA,
  LOCALES.en_NR,
  LOCALES.en_NZ,
  LOCALES.en_NG,
  LOCALES.en_NU,
  LOCALES.en_NF,
  LOCALES.en_MP,
  LOCALES.en_PK,
  LOCALES.en_PW,
  LOCALES.en_PG,
  LOCALES.en_PH,
  LOCALES.en_PN,
  LOCALES.en_PR,
  LOCALES.en_RW,
  LOCALES.en_KN,
  LOCALES.en_LC,
  LOCALES.en_VC,
  LOCALES.en_WS,
  LOCALES.en_SC,
  LOCALES.en_SL,
  LOCALES.en_SG,
  LOCALES.en_SB,
  LOCALES.en_ZA,
  LOCALES.en_GS,
  LOCALES.en_SS,
  LOCALES.en_LK,
  LOCALES.en_SR,
  LOCALES.en_TZ,
  LOCALES.en_TO,
  LOCALES.en_TT,
  LOCALES.en_TC,
  LOCALES.en_TV,
  LOCALES.en_UG,
  LOCALES.en_GB,
  LOCALES.en_US,
  LOCALES.en_UM,
  LOCALES.en_VI,
  LOCALES.en_WF,
  LOCALES.en_EH,
  LOCALES.en_YE,
  LOCALES.en_ZM,
  LOCALES.en_ZW,
  LOCALES.en_BH,
  LOCALES.en_BD,
  LOCALES.en_BT,
  LOCALES.en_BN,
  LOCALES.en_CY,
  LOCALES.en_ER,
  LOCALES.en_IR,
  LOCALES.en_IQ,
  LOCALES.en_JO,
  LOCALES.en_KW,
  LOCALES.en_LB,
  LOCALES.en_LY,
  LOCALES.en_MV,
  LOCALES.en_MM,
  LOCALES.en_NP,
  LOCALES.en_OM,
  LOCALES.en_QA,
  LOCALES.en_SO,
  LOCALES.en_SD,
  LOCALES.en_SY,
  LOCALES.en_TL,
  LOCALES.en_AE,
] as const;

export const LOCALE_NAMES = {
  [LOCALES.en_AI]: "Anguilla",
  [LOCALES.en_AG]: "Antigua and Barbuda",
  [LOCALES.en_AU]: "Australia",
  [LOCALES.en_BS]: "Bahamas",
  [LOCALES.en_BB]: "Barbados",
  [LOCALES.en_BZ]: "Belize",
  [LOCALES.en_BM]: "Bermuda",
  [LOCALES.en_BW]: "Botswana",
  [LOCALES.en_IO]: "British Indian Ocean Territory",
  [LOCALES.en_VG]: "British Virgin Islands",
  [LOCALES.en_CM]: "Cameroon",
  [LOCALES.en_CA]: "Canada",
  [LOCALES.en_KY]: "Cayman Islands",
  [LOCALES.en_CK]: "Cook Islands",
  [LOCALES.en_DK]: "Denmark",
  [LOCALES.en_DM]: "Dominica",
  [LOCALES.en_SZ]: "Eswatini",
  [LOCALES.en_FK]: "Falkland Islands",
  [LOCALES.en_FJ]: "Fiji",
  [LOCALES.en_GM]: "Gambia",
  [LOCALES.en_GH]: "Ghana",
  [LOCALES.en_GI]: "Gibraltar",
  [LOCALES.en_GD]: "Grenada",
  [LOCALES.en_GU]: "Guam",
  [LOCALES.en_GY]: "Guyana",
  [LOCALES.en_IN]: "India",
  [LOCALES.en_IE]: "Ireland",
  [LOCALES.en_JM]: "Jamaica",
  [LOCALES.en_KE]: "Kenya",
  [LOCALES.en_KI]: "Kiribati",
  [LOCALES.en_LS]: "Lesotho",
  [LOCALES.en_LR]: "Liberia",
  [LOCALES.en_MW]: "Malawi",
  [LOCALES.en_MY]: "Malaysia",
  [LOCALES.en_MT]: "Malta",
  [LOCALES.en_MH]: "Marshall Islands",
  [LOCALES.en_MU]: "Mauritius",
  [LOCALES.en_MS]: "Montserrat",
  [LOCALES.en_NA]: "Namibia",
  [LOCALES.en_NR]: "Nauru",
  [LOCALES.en_NZ]: "New Zealand",
  [LOCALES.en_NG]: "Nigeria",
  [LOCALES.en_NU]: "Niue",
  [LOCALES.en_NF]: "Norfolk Island",
  [LOCALES.en_MP]: "Northern Mariana Islands",
  [LOCALES.en_PK]: "Pakistan",
  [LOCALES.en_PW]: "Palau",
  [LOCALES.en_PG]: "Papua New Guinea",
  [LOCALES.en_PH]: "Philippines",
  [LOCALES.en_PN]: "Pitcairn Islands",
  [LOCALES.en_PR]: "Puerto Rico",
  [LOCALES.en_RW]: "Rwanda",
  [LOCALES.en_KN]: "Saint Kitts and Nevis",
  [LOCALES.en_LC]: "Saint Lucia",
  [LOCALES.en_VC]: "Saint Vincent and the Grenadines",
  [LOCALES.en_WS]: "Samoa",
  [LOCALES.en_SC]: "Seychelles",
  [LOCALES.en_SL]: "Sierra Leone",
  [LOCALES.en_SG]: "Singapore",
  [LOCALES.en_SB]: "Solomon Islands",
  [LOCALES.en_ZA]: "South Africa",
  [LOCALES.en_GS]: "South Georgia and the South Sandwich Islands",
  [LOCALES.en_SS]: "South Sudan",
  [LOCALES.en_LK]: "Sri Lanka",
  [LOCALES.en_SR]: "Suriname",
  [LOCALES.en_TZ]: "Tanzania",
  [LOCALES.en_TO]: "Tonga",
  [LOCALES.en_TT]: "Trinidad and Tobago",
  [LOCALES.en_TC]: "Turks and Caicos Islands",
  [LOCALES.en_TV]: "Tuvalu",
  [LOCALES.en_UG]: "Uganda",
  [LOCALES.en_GB]: "United Kingdom",
  [LOCALES.en_US]: "United States",
  [LOCALES.en_UM]: "United States Minor Outlying Islands",
  [LOCALES.en_VI]: "Virgin Islands, U.S.",
  [LOCALES.en_WF]: "Wallis and Futuna",
  [LOCALES.en_EH]: "Western Sahara",
  [LOCALES.en_YE]: "Yemen",
  [LOCALES.en_ZM]: "Zambia",
  [LOCALES.en_ZW]: "Zimbabwe",
  [LOCALES.en_BH]: "Bahrain",
  [LOCALES.en_BD]: "Bangladesh",
  [LOCALES.en_BT]: "Bhutan",
  [LOCALES.en_BN]: "Brunei",
  [LOCALES.en_CY]: "Cyprus",
  [LOCALES.en_ER]: "Eritrea",
  [LOCALES.en_IR]: "Iran",
  [LOCALES.en_IQ]: "Iraq",
  [LOCALES.en_JO]: "Jordan",
  [LOCALES.en_KW]: "Kuwait",
  [LOCALES.en_LB]: "Lebanon",
  [LOCALES.en_LY]: "Libya",
  [LOCALES.en_MV]: "Maldives",
  [LOCALES.en_MM]: "Myanmar (Burma)",
  [LOCALES.en_NP]: "Nepal",
  [LOCALES.en_OM]: "Oman",
  [LOCALES.en_QA]: "Qatar",
  [LOCALES.en_SO]: "Somalia",
  [LOCALES.en_SD]: "Sudan",
  [LOCALES.en_SY]: "Syria",
  [LOCALES.en_TL]: "Timor-Leste",
  [LOCALES.en_AE]: "United Arab Emirates",
} as const;
