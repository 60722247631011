export const COUNTRIES = {
  AD: "AD",
  AE: "AE",
  AF: "AF",
  AG: "AG",
  AI: "AI",
  AL: "AL",
  AM: "AM",
  AN: "AN",
  AO: "AO",
  AQ: "AQ",
  AR: "AR",
  AS: "AS",
  AT: "AT",
  AU: "AU",
  AW: "AW",
  AX: "AX",
  AZ: "AZ",
  BA: "BA",
  BB: "BB",
  BD: "BD",
  BE: "BE",
  BF: "BF",
  BG: "BG",
  BH: "BH",
  BI: "BI",
  BJ: "BJ",
  BM: "BM",
  BN: "BN",
  BO: "BO",
  BR: "BR",
  BS: "BS",
  BT: "BT",
  BV: "BV",
  BW: "BW",
  BY: "BY",
  BZ: "BZ",
  CA: "CA",
  CC: "CC",
  CD: "CD",
  CF: "CF",
  CG: "CG",
  CH: "CH",
  CI: "CI",
  CK: "CK",
  CL: "CL",
  CM: "CM",
  CN: "CN",
  CO: "CO",
  CR: "CR",
  CS: "CS",
  CU: "CU",
  CV: "CV",
  CX: "CX",
  CY: "CY",
  CZ: "CZ",
  DE: "DE",
  DJ: "DJ",
  DK: "DK",
  DM: "DM",
  DO: "DO",
  DZ: "DZ",
  EC: "EC",
  EE: "EE",
  EG: "EG",
  EH: "EH",
  ER: "ER",
  ES: "ES",
  ET: "ET",
  FI: "FI",
  FJ: "FJ",
  FK: "FK",
  FM: "FM",
  FO: "FO",
  FR: "FR",
  GA: "GA",
  GB: "GB",
  GD: "GD",
  GE: "GE",
  GF: "GF",
  GG: "GG",
  GH: "GH",
  GI: "GI",
  GL: "GL",
  GM: "GM",
  GN: "GN",
  GP: "GP",
  GQ: "GQ",
  GR: "GR",
  GS: "GS",
  GT: "GT",
  GU: "GU",
  GW: "GW",
  GY: "GY",
  HK: "HK",
  HM: "HM",
  HN: "HN",
  HR: "HR",
  HT: "HT",
  HU: "HU",
  ID: "ID",
  IE: "IE",
  IL: "IL",
  IM: "IM",
  IN: "IN",
  IO: "IO",
  IQ: "IQ",
  IR: "IR",
  IS: "IS",
  IT: "IT",
  JE: "JE",
  JM: "JM",
  JO: "JO",
  JP: "JP",
  KE: "KE",
  KG: "KG",
  KH: "KH",
  KI: "KI",
  KM: "KM",
  KN: "KN",
  KP: "KP",
  KR: "KR",
  KW: "KW",
  KY: "KY",
  KZ: "KZ",
  LA: "LA",
  LB: "LB",
  LC: "LC",
  LI: "LI",
  LK: "LK",
  LR: "LR",
  LS: "LS",
  LT: "LT",
  LU: "LU",
  LV: "LV",
  LY: "LY",
  MA: "MA",
  MC: "MC",
  MD: "MD",
  MG: "MG",
  MH: "MH",
  MK: "MK",
  ML: "ML",
  MM: "MM",
  MN: "MN",
  MO: "MO",
  MP: "MP",
  MQ: "MQ",
  MR: "MR",
  MS: "MS",
  MT: "MT",
  MU: "MU",
  MV: "MV",
  MW: "MW",
  MX: "MX",
  MY: "MY",
  MZ: "MZ",
  NA: "NA",
  NC: "NC",
  NE: "NE",
  NF: "NF",
  NG: "NG",
  NI: "NI",
  NL: "NL",
  NO: "NO",
  NP: "NP",
  NR: "NR",
  NU: "NU",
  NZ: "NZ",
  OM: "OM",
  PA: "PA",
  PE: "PE",
  PF: "PF",
  PG: "PG",
  PH: "PH",
  PK: "PK",
  PL: "PL",
  PM: "PM",
  PN: "PN",
  PR: "PR",
  PS: "PS",
  PT: "PT",
  PW: "PW",
  PY: "PY",
  QA: "QA",
  RE: "RE",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  SA: "SA",
  SB: "SB",
  SC: "SC",
  SD: "SD",
  SE: "SE",
  SG: "SG",
  SH: "SH",
  SI: "SI",
  SJ: "SJ",
  SK: "SK",
  SL: "SL",
  SM: "SM",
  SN: "SN",
  SO: "SO",
  SR: "SR",
  ST: "ST",
  SV: "SV",
  SY: "SY",
  SZ: "SZ",
  TC: "TC",
  TD: "TD",
  TF: "TF",
  TG: "TG",
  TH: "TH",
  TJ: "TJ",
  TK: "TK",
  TL: "TL",
  TM: "TM",
  TN: "TN",
  TO: "TO",
  TR: "TR",
  TT: "TT",
  TV: "TV",
  TW: "TW",
  TZ: "TZ",
  UA: "UA",
  UG: "UG",
  UM: "UM",
  US: "US",
  UY: "UY",
  UZ: "UZ",
  VA: "VA",
  VC: "VC",
  VE: "VE",
  VG: "VG",
  VI: "VI",
  VN: "VN",
  VU: "VU",
  WF: "WF",
  WS: "WS",
  YE: "YE",
  YT: "YT",
  ZA: "ZA",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export const COUNTRY_VALUES = [
  COUNTRIES.AD,
  COUNTRIES.AE,
  COUNTRIES.AF,
  COUNTRIES.AG,
  COUNTRIES.AI,
  COUNTRIES.AL,
  COUNTRIES.AM,
  COUNTRIES.AN,
  COUNTRIES.AO,
  COUNTRIES.AQ,
  COUNTRIES.AR,
  COUNTRIES.AS,
  COUNTRIES.AT,
  COUNTRIES.AU,
  COUNTRIES.AW,
  COUNTRIES.AX,
  COUNTRIES.AZ,
  COUNTRIES.BA,
  COUNTRIES.BB,
  COUNTRIES.BD,
  COUNTRIES.BE,
  COUNTRIES.BF,
  COUNTRIES.BG,
  COUNTRIES.BH,
  COUNTRIES.BI,
  COUNTRIES.BJ,
  COUNTRIES.BM,
  COUNTRIES.BN,
  COUNTRIES.BO,
  COUNTRIES.BR,
  COUNTRIES.BS,
  COUNTRIES.BT,
  COUNTRIES.BV,
  COUNTRIES.BW,
  COUNTRIES.BY,
  COUNTRIES.BZ,
  COUNTRIES.CA,
  COUNTRIES.CC,
  COUNTRIES.CD,
  COUNTRIES.CF,
  COUNTRIES.CG,
  COUNTRIES.CH,
  COUNTRIES.CI,
  COUNTRIES.CK,
  COUNTRIES.CL,
  COUNTRIES.CM,
  COUNTRIES.CN,
  COUNTRIES.CO,
  COUNTRIES.CR,
  COUNTRIES.CS,
  COUNTRIES.CU,
  COUNTRIES.CV,
  COUNTRIES.CX,
  COUNTRIES.CY,
  COUNTRIES.CZ,
  COUNTRIES.DE,
  COUNTRIES.DJ,
  COUNTRIES.DK,
  COUNTRIES.DM,
  COUNTRIES.DO,
  COUNTRIES.DZ,
  COUNTRIES.EC,
  COUNTRIES.EE,
  COUNTRIES.EG,
  COUNTRIES.EH,
  COUNTRIES.ER,
  COUNTRIES.ES,
  COUNTRIES.ET,
  COUNTRIES.FI,
  COUNTRIES.FJ,
  COUNTRIES.FK,
  COUNTRIES.FM,
  COUNTRIES.FO,
  COUNTRIES.FR,
  COUNTRIES.GA,
  COUNTRIES.GB,
  COUNTRIES.GD,
  COUNTRIES.GE,
  COUNTRIES.GF,
  COUNTRIES.GG,
  COUNTRIES.GH,
  COUNTRIES.GI,
  COUNTRIES.GL,
  COUNTRIES.GM,
  COUNTRIES.GN,
  COUNTRIES.GP,
  COUNTRIES.GQ,
  COUNTRIES.GR,
  COUNTRIES.GS,
  COUNTRIES.GT,
  COUNTRIES.GU,
  COUNTRIES.GW,
  COUNTRIES.GY,
  COUNTRIES.HK,
  COUNTRIES.HM,
  COUNTRIES.HN,
  COUNTRIES.HR,
  COUNTRIES.HT,
  COUNTRIES.HU,
  COUNTRIES.ID,
  COUNTRIES.IE,
  COUNTRIES.IL,
  COUNTRIES.IM,
  COUNTRIES.IN,
  COUNTRIES.IO,
  COUNTRIES.IQ,
  COUNTRIES.IR,
  COUNTRIES.IS,
  COUNTRIES.IT,
  COUNTRIES.JE,
  COUNTRIES.JM,
  COUNTRIES.JO,
  COUNTRIES.JP,
  COUNTRIES.KE,
  COUNTRIES.KG,
  COUNTRIES.KH,
  COUNTRIES.KI,
  COUNTRIES.KM,
  COUNTRIES.KN,
  COUNTRIES.KP,
  COUNTRIES.KR,
  COUNTRIES.KW,
  COUNTRIES.KY,
  COUNTRIES.KZ,
  COUNTRIES.LA,
  COUNTRIES.LB,
  COUNTRIES.LC,
  COUNTRIES.LI,
  COUNTRIES.LK,
  COUNTRIES.LR,
  COUNTRIES.LS,
  COUNTRIES.LT,
  COUNTRIES.LU,
  COUNTRIES.LV,
  COUNTRIES.LY,
  COUNTRIES.MA,
  COUNTRIES.MC,
  COUNTRIES.MD,
  COUNTRIES.MG,
  COUNTRIES.MH,
  COUNTRIES.MK,
  COUNTRIES.ML,
  COUNTRIES.MM,
  COUNTRIES.MN,
  COUNTRIES.MO,
  COUNTRIES.MP,
  COUNTRIES.MQ,
  COUNTRIES.MR,
  COUNTRIES.MS,
  COUNTRIES.MT,
  COUNTRIES.MU,
  COUNTRIES.MV,
  COUNTRIES.MW,
  COUNTRIES.MX,
  COUNTRIES.MY,
  COUNTRIES.MZ,
  COUNTRIES.NA,
  COUNTRIES.NC,
  COUNTRIES.NE,
  COUNTRIES.NF,
  COUNTRIES.NG,
  COUNTRIES.NI,
  COUNTRIES.NL,
  COUNTRIES.NO,
  COUNTRIES.NP,
  COUNTRIES.NR,
  COUNTRIES.NU,
  COUNTRIES.NZ,
  COUNTRIES.OM,
  COUNTRIES.PA,
  COUNTRIES.PE,
  COUNTRIES.PF,
  COUNTRIES.PG,
  COUNTRIES.PH,
  COUNTRIES.PK,
  COUNTRIES.PL,
  COUNTRIES.PM,
  COUNTRIES.PN,
  COUNTRIES.PR,
  COUNTRIES.PS,
  COUNTRIES.PT,
  COUNTRIES.PW,
  COUNTRIES.PY,
  COUNTRIES.QA,
  COUNTRIES.RE,
  COUNTRIES.RO,
  COUNTRIES.RU,
  COUNTRIES.RW,
  COUNTRIES.SA,
  COUNTRIES.SB,
  COUNTRIES.SC,
  COUNTRIES.SD,
  COUNTRIES.SE,
  COUNTRIES.SG,
  COUNTRIES.SH,
  COUNTRIES.SI,
  COUNTRIES.SJ,
  COUNTRIES.SK,
  COUNTRIES.SL,
  COUNTRIES.SM,
  COUNTRIES.SN,
  COUNTRIES.SO,
  COUNTRIES.SR,
  COUNTRIES.ST,
  COUNTRIES.SV,
  COUNTRIES.SY,
  COUNTRIES.SZ,
  COUNTRIES.TC,
  COUNTRIES.TD,
  COUNTRIES.TF,
  COUNTRIES.TG,
  COUNTRIES.TH,
  COUNTRIES.TJ,
  COUNTRIES.TK,
  COUNTRIES.TL,
  COUNTRIES.TM,
  COUNTRIES.TN,
  COUNTRIES.TO,
  COUNTRIES.TR,
  COUNTRIES.TT,
  COUNTRIES.TV,
  COUNTRIES.TW,
  COUNTRIES.TZ,
  COUNTRIES.UA,
  COUNTRIES.UG,
  COUNTRIES.UM,
  COUNTRIES.US,
  COUNTRIES.UY,
  COUNTRIES.UZ,
  COUNTRIES.VA,
  COUNTRIES.VC,
  COUNTRIES.VE,
  COUNTRIES.VG,
  COUNTRIES.VI,
  COUNTRIES.VN,
  COUNTRIES.VU,
  COUNTRIES.WF,
  COUNTRIES.WS,
  COUNTRIES.YE,
  COUNTRIES.YT,
  COUNTRIES.ZA,
  COUNTRIES.ZM,
  COUNTRIES.ZW,
] as const;

export const COUNTRY_NAMES = {
  [COUNTRIES.AD]: "Andorra",
  [COUNTRIES.AE]: "United Arab Emirates",
  [COUNTRIES.AF]: "Afghanistan",
  [COUNTRIES.AG]: "Antigua and Barbuda",
  [COUNTRIES.AI]: "Anguilla",
  [COUNTRIES.AL]: "Albania",
  [COUNTRIES.AM]: "Armenia",
  [COUNTRIES.AN]: "Netherlands Antilles",
  [COUNTRIES.AO]: "Angola",
  [COUNTRIES.AQ]: "Antarctica",
  [COUNTRIES.AR]: "Argentina",
  [COUNTRIES.AS]: "American Samoa",
  [COUNTRIES.AT]: "Austria",
  [COUNTRIES.AU]: "Australia",
  [COUNTRIES.AW]: "Aruba",
  [COUNTRIES.AX]: "Åland Islands",
  [COUNTRIES.AZ]: "Azerbaijan",
  [COUNTRIES.BA]: "Bosnia and Herzegovina",
  [COUNTRIES.BB]: "Barbados",
  [COUNTRIES.BD]: "Bangladesh",
  [COUNTRIES.BE]: "Belgium",
  [COUNTRIES.BF]: "Burkina Faso",
  [COUNTRIES.BG]: "Bulgaria",
  [COUNTRIES.BH]: "Bahrain",
  [COUNTRIES.BI]: "Burundi",
  [COUNTRIES.BJ]: "Benin",
  [COUNTRIES.BM]: "Bermuda",
  [COUNTRIES.BN]: "Brunei Darussalam",
  [COUNTRIES.BO]: "Bolivia",
  [COUNTRIES.BR]: "Brazil",
  [COUNTRIES.BS]: "Bahamas",
  [COUNTRIES.BT]: "Bhutan",
  [COUNTRIES.BV]: "Bouvet Island",
  [COUNTRIES.BW]: "Botswana",
  [COUNTRIES.BY]: "Belarus",
  [COUNTRIES.BZ]: "Belize",
  [COUNTRIES.CA]: "Canada",
  [COUNTRIES.CC]: "Cocos (Keeling) Islands",
  [COUNTRIES.CD]: "Congo, The Democratic Republic of the",
  [COUNTRIES.CF]: "Central African Republic",
  [COUNTRIES.CG]: "Congo",
  [COUNTRIES.CH]: "Switzerland",
  [COUNTRIES.CI]: "Cote D'Ivoire",
  [COUNTRIES.CK]: "Cook Islands",
  [COUNTRIES.CL]: "Chile",
  [COUNTRIES.CM]: "Cameroon",
  [COUNTRIES.CN]: "China",
  [COUNTRIES.CO]: "Colombia",
  [COUNTRIES.CR]: "Costa Rica",
  [COUNTRIES.CS]: "Serbia and Montenegro",
  [COUNTRIES.CU]: "Cuba",
  [COUNTRIES.CV]: "Cape Verde",
  [COUNTRIES.CX]: "Christmas Island",
  [COUNTRIES.CY]: "Cyprus",
  [COUNTRIES.CZ]: "Czech Republic",
  [COUNTRIES.DE]: "Germany",
  [COUNTRIES.DJ]: "Djibouti",
  [COUNTRIES.DK]: "Denmark",
  [COUNTRIES.DM]: "Dominica",
  [COUNTRIES.DO]: "Dominican Republic",
  [COUNTRIES.DZ]: "Algeria",
  [COUNTRIES.EC]: "Ecuador",
  [COUNTRIES.EE]: "Estonia",
  [COUNTRIES.EG]: "Egypt",
  [COUNTRIES.EH]: "Western Sahara",
  [COUNTRIES.ER]: "Eritrea",
  [COUNTRIES.ES]: "Spain",
  [COUNTRIES.ET]: "Ethiopia",
  [COUNTRIES.FI]: "Finland",
  [COUNTRIES.FJ]: "Fiji",
  [COUNTRIES.FK]: "Falkland Islands (Malvinas)",
  [COUNTRIES.FM]: "Micronesia, Federated States of",
  [COUNTRIES.FO]: "Faroe Islands",
  [COUNTRIES.FR]: "France",
  [COUNTRIES.GA]: "Gabon",
  [COUNTRIES.GB]: "United Kingdom",
  [COUNTRIES.GD]: "Grenada",
  [COUNTRIES.GE]: "Georgia",
  [COUNTRIES.GF]: "French Guiana",
  [COUNTRIES.GG]: "Guernsey",
  [COUNTRIES.GH]: "Ghana",
  [COUNTRIES.GI]: "Gibraltar",
  [COUNTRIES.GL]: "Greenland",
  [COUNTRIES.GM]: "Gambia",
  [COUNTRIES.GN]: "Guinea",
  [COUNTRIES.GP]: "Guadeloupe",
  [COUNTRIES.GQ]: "Equatorial Guinea",
  [COUNTRIES.GR]: "Greece",
  [COUNTRIES.GS]: "South Georgia and the South Sandwich Islands",
  [COUNTRIES.GT]: "Guatemala",
  [COUNTRIES.GU]: "Guam",
  [COUNTRIES.GW]: "Guinea-Bissau",
  [COUNTRIES.GY]: "Guyana",
  [COUNTRIES.HK]: "Hong Kong",
  [COUNTRIES.HM]: "Heard Island and Mcdonald Islands",
  [COUNTRIES.HN]: "Honduras",
  [COUNTRIES.HR]: "Croatia",
  [COUNTRIES.HT]: "Haiti",
  [COUNTRIES.HU]: "Hungary",
  [COUNTRIES.ID]: "Indonesia",
  [COUNTRIES.IE]: "Ireland",
  [COUNTRIES.IL]: "Israel",
  [COUNTRIES.IM]: "Isle of Man",
  [COUNTRIES.IN]: "India",
  [COUNTRIES.IO]: "British Indian Ocean Territory",
  [COUNTRIES.IQ]: "Iraq",
  [COUNTRIES.IR]: "Iran, Islamic Republic Of",
  [COUNTRIES.IS]: "Iceland",
  [COUNTRIES.IT]: "Italy",
  [COUNTRIES.JE]: "Jersey",
  [COUNTRIES.JM]: "Jamaica",
  [COUNTRIES.JO]: "Jordan",
  [COUNTRIES.JP]: "Japan",
  [COUNTRIES.KE]: "Kenya",
  [COUNTRIES.KG]: "Kyrgyzstan",
  [COUNTRIES.KH]: "Cambodia",
  [COUNTRIES.KI]: "Kiribati",
  [COUNTRIES.KM]: "Comoros",
  [COUNTRIES.KN]: "Saint Kitts and Nevis",
  [COUNTRIES.KP]: "Korea, Democratic People'S Republic of",
  [COUNTRIES.KR]: "Korea, Republic of",
  [COUNTRIES.KW]: "Kuwait",
  [COUNTRIES.KY]: "Cayman Islands",
  [COUNTRIES.KZ]: "Kazakhstan",
  [COUNTRIES.LA]: "Lao People'S Democratic Republic",
  [COUNTRIES.LB]: "Lebanon",
  [COUNTRIES.LC]: "Saint Lucia",
  [COUNTRIES.LI]: "Liechtenstein",
  [COUNTRIES.LK]: "Sri Lanka",
  [COUNTRIES.LR]: "Liberia",
  [COUNTRIES.LS]: "Lesotho",
  [COUNTRIES.LT]: "Lithuania",
  [COUNTRIES.LU]: "Luxembourg",
  [COUNTRIES.LV]: "Latvia",
  [COUNTRIES.LY]: "Libyan Arab Jamahiriya",
  [COUNTRIES.MA]: "Morocco",
  [COUNTRIES.MC]: "Monaco",
  [COUNTRIES.MD]: "Moldova, Republic of",
  [COUNTRIES.MG]: "Madagascar",
  [COUNTRIES.MH]: "Marshall Islands",
  [COUNTRIES.MK]: "Macedonia, The Former Yugoslav Republic of",
  [COUNTRIES.ML]: "Mali",
  [COUNTRIES.MM]: "Myanmar",
  [COUNTRIES.MN]: "Mongolia",
  [COUNTRIES.MO]: "Macao",
  [COUNTRIES.MP]: "Northern Mariana Islands",
  [COUNTRIES.MQ]: "Martinique",
  [COUNTRIES.MR]: "Mauritania",
  [COUNTRIES.MS]: "Montserrat",
  [COUNTRIES.MT]: "Malta",
  [COUNTRIES.MU]: "Mauritius",
  [COUNTRIES.MV]: "Maldives",
  [COUNTRIES.MW]: "Malawi",
  [COUNTRIES.MX]: "Mexico",
  [COUNTRIES.MY]: "Malaysia",
  [COUNTRIES.MZ]: "Mozambique",
  [COUNTRIES.NA]: "Namibia",
  [COUNTRIES.NC]: "New Caledonia",
  [COUNTRIES.NE]: "Niger",
  [COUNTRIES.NF]: "Norfolk Island",
  [COUNTRIES.NG]: "Nigeria",
  [COUNTRIES.NI]: "Nicaragua",
  [COUNTRIES.NL]: "Netherlands",
  [COUNTRIES.NO]: "Norway",
  [COUNTRIES.NP]: "Nepal",
  [COUNTRIES.NR]: "Nauru",
  [COUNTRIES.NU]: "Niue",
  [COUNTRIES.NZ]: "New Zealand",
  [COUNTRIES.OM]: "Oman",
  [COUNTRIES.PA]: "Panama",
  [COUNTRIES.PE]: "Peru",
  [COUNTRIES.PF]: "French Polynesia",
  [COUNTRIES.PG]: "Papua New Guinea",
  [COUNTRIES.PH]: "Philippines",
  [COUNTRIES.PK]: "Pakistan",
  [COUNTRIES.PL]: "Poland",
  [COUNTRIES.PM]: "Saint Pierre and Miquelon",
  [COUNTRIES.PN]: "Pitcairn",
  [COUNTRIES.PR]: "Puerto Rico",
  [COUNTRIES.PS]: "Palestinian Territory, Occupied",
  [COUNTRIES.PT]: "Portugal",
  [COUNTRIES.PW]: "Palau",
  [COUNTRIES.PY]: "Paraguay",
  [COUNTRIES.QA]: "Qatar",
  [COUNTRIES.RE]: "Reunion",
  [COUNTRIES.RO]: "Romania",
  [COUNTRIES.RU]: "Russian Federation",
  [COUNTRIES.RW]: "RWANDA",
  [COUNTRIES.SA]: "Saudi Arabia",
  [COUNTRIES.SB]: "Solomon Islands",
  [COUNTRIES.SC]: "Seychelles",
  [COUNTRIES.SD]: "Sudan",
  [COUNTRIES.SE]: "Sweden",
  [COUNTRIES.SG]: "Singapore",
  [COUNTRIES.SH]: "Saint Helena",
  [COUNTRIES.SI]: "Slovenia",
  [COUNTRIES.SJ]: "Svalbard and Jan Mayen",
  [COUNTRIES.SK]: "Slovakia",
  [COUNTRIES.SL]: "Sierra Leone",
  [COUNTRIES.SM]: "San Marino",
  [COUNTRIES.SN]: "Senegal",
  [COUNTRIES.SO]: "Somalia",
  [COUNTRIES.SR]: "Suriname",
  [COUNTRIES.ST]: "Sao Tome and Principe",
  [COUNTRIES.SV]: "El Salvador",
  [COUNTRIES.SY]: "Syrian Arab Republic",
  [COUNTRIES.SZ]: "Swaziland",
  [COUNTRIES.TC]: "Turks and Caicos Islands",
  [COUNTRIES.TD]: "Chad",
  [COUNTRIES.TF]: "French Southern Territories",
  [COUNTRIES.TG]: "Togo",
  [COUNTRIES.TH]: "Thailand",
  [COUNTRIES.TJ]: "Tajikistan",
  [COUNTRIES.TK]: "Tokelau",
  [COUNTRIES.TL]: "Timor-Leste",
  [COUNTRIES.TM]: "Turkmenistan",
  [COUNTRIES.TN]: "Tunisia",
  [COUNTRIES.TO]: "Tonga",
  [COUNTRIES.TR]: "Turkey",
  [COUNTRIES.TT]: "Trinidad and Tobago",
  [COUNTRIES.TV]: "Tuvalu",
  [COUNTRIES.TW]: "Taiwan, Province of China",
  [COUNTRIES.TZ]: "Tanzania, United Republic of",
  [COUNTRIES.UA]: "Ukraine",
  [COUNTRIES.UG]: "Uganda",
  [COUNTRIES.UM]: "United States Minor Outlying Islands",
  [COUNTRIES.US]: "United States",
  [COUNTRIES.UY]: "Uruguay",
  [COUNTRIES.UZ]: "Uzbekistan",
  [COUNTRIES.VA]: "Holy See (Vatican City State)",
  [COUNTRIES.VC]: "Saint Vincent and the Grenadines",
  [COUNTRIES.VE]: "Venezuela",
  [COUNTRIES.VG]: "Virgin Islands, British",
  [COUNTRIES.VI]: "Virgin Islands, U.S.",
  [COUNTRIES.VN]: "Viet Nam",
  [COUNTRIES.VU]: "Vanuatu",
  [COUNTRIES.WF]: "Wallis and Futuna",
  [COUNTRIES.WS]: "Samoa",
  [COUNTRIES.YE]: "Yemen",
  [COUNTRIES.YT]: "Mayotte",
  [COUNTRIES.ZA]: "South Africa",
  [COUNTRIES.ZM]: "Zambia",
  [COUNTRIES.ZW]: "Zimbabwe",
} as const;
